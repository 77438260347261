import React from "react";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { graphql, navigate, useStaticQuery } from "gatsby";
import { makeStyles } from "@material-ui/styles";
import themeOptions from "../../../.brew-cache/theme.json";
import Link from "../Link";
import { GatsbyImage } from "gatsby-plugin-image";
import DragScroll from "react-dragscroll";
import { replaceMasks } from "../../services/masks";
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import styles from "./styles";
import ReactHtmlParser from "react-html-parser";
import { formatDate } from "../../helpers";

const useStyles = makeStyles((theme) => styles(theme));

export const BlogListing = (props) => {
  const classes = useStyles();

  const postPerPage = 5;

  const [page, setPage] = useState(0);
  const [allInView, setAllInView] = useState(false);
  const [visibleData, setVisibleData] = useState([]);

  useEffect(() => {
    const numberOfItems = postPerPage * (page + 1);

    const newArray = [];

    for (let i = 0; i < posts.length; i++) {
      if (i < numberOfItems) newArray.push(posts[i]);
    }

    setVisibleData(newArray);

    if (numberOfItems >= posts.length) {
      setAllInView(true);
    }
  }, [page]);

  let { posts, pageNumber, totalPages } = props.pageContext;

  // Toggle output based on Attributes
  // props.attributes.display : list / grid

  // Query out the sticky post
  const data = useStaticQuery(graphql`
    query CatListQuery {
      allWpCategory {
        nodes {
          id
          uri
          slug
          name
        }
      }
      allWp {
        nodes {
          locationData {
            LocationData {
              facebookHandle
              instagramHandle
              twitterHandle
            }
          }
        }
      }
    }
  `);

  if (typeof posts === "undefined") {
    // set some sample data
    posts = [
      {
        node: {
          title: "Example Post",
          date: "2020-01-01 00:00:00",
          excerpt:
            "<p>This is just a test post used for previewing the listing page. Real posts will appear here when this page is published.</p>",
          uri: "/",
          featuredImage: {
            node: {
              mediaItemUrl: "",
            },
          },
        },
      },
    ];
  }

  let featuredSticky = false;
  const featured = {};
  if (props.sticky) {
    // Use the sticky post first
    featuredSticky = props.sticky.id;
    let image = null;

    if (props.sticky.featuredImage.node.localFile) {
      if (props.sticky.featuredImage.node.localFile.childImageSharp) {
        image = (
          <GatsbyImage
            image={
              props.sticky.featuredImage.node.localFile.childImageSharp
                .gatsbyImageData
            }
            alt=""
          />
        );
      } else {
        // Gifs do not have a childImageSharp
        image = (
          <div className="gatsby-image-wrapper">
            <div style={{ paddingTop: "55%" }} />
            <img
              src={props.sticky.featuredImage.node.localFile.publicURL}
              alt=""
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                objectFit: "cover",
                objectPosition: "center center",
              }}
            />
          </div>
        );
      }
    } else {
      // This is a preview image
      image = (
        <div className="gatsby-image-wrapper">
          <div style={{ paddingTop: "55%" }} />
          <img
            src={props.sticky.featuredImage.node.mediaItemUrl}
            alt=""
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: "cover",
              objectPosition: "center center",
            }}
          />
        </div>
      );
    }

    featured.id = props.sticky.id;
    featured.uri = props.sticky.slug;
    featured.image = image;
    featured.title = props.sticky.title;
    featured.date = new Date(props.sticky.date);
    featured.date = formatDate(featured.date);
  } else {
    if (posts.length > 0) {
      let image = null;
      if (posts[0].featuredImage.node.localFile) {
        if (posts[0].featuredImage.node.localFile.childImageSharp) {
          image = (
            <GatsbyImage
              image={
                posts[0].featuredImage.node.localFile.childImageSharp
                  .gatsbyImageData
              }
              alt={posts[0].featuredImage.node.altText}
            />
          );
        } else {
          // Gifs do not have a childImageSharp
          image = (
            <div className="gatsby-image-wrapper">
              <div style={{ paddingTop: "55%" }} />
              <img
                src={posts[0].featuredImage.node.localFile.publicURL}
                alt={posts[0].featuredImage.node.altText}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  objectPosition: "center center",
                }}
              />
            </div>
          );
        }
      } else {
        // This is a preview image
        image = (
          <div className="gatsby-image-wrapper">
            <div style={{ paddingTop: "55%" }} />
            <img
              src={posts[0].featuredImage.node.mediaUrl}
              alt={posts[0].featuredImage.node.altText}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                objectFit: "cover",
                objectPosition: "center center",
              }}
            />
          </div>
        );
      }

      featured.id = posts[0].id;
      featured.uri = posts[0].slug;
      featured.image = image;
      featured.title = posts[0].title;
      featured.date = new Date(posts[0].date);
      featured.date = formatDate(featured.date);
    }
  }

  return (
    <div
      className={`BlogListing ${classes.blogPagePosts} ${classes.blogPagePostsTheme}`}
    >
      <div className="blog-list-banner">
        <h1 color="initial" align="left">
          Latest News
        </h1>
      </div>
      <div className="blog-list">
        <div className="featured-item">
          <Link to={`/${themeOptions.blogPath}/${featured.uri}`}>
            {featured.image}
            <div className="top-meta">
              <span className="line"></span>
              <span className="date">{featured.date}</span>
            </div>
            <div className="cta-wrap">
              <span className="date">{featured.date}</span>
              <span className="post-title">{featured.title}</span>
              <span className="cta">Read More</span>
            </div>
          </Link>
        </div>
        <div className="listing-title">
          <h2>Views, News and Brews</h2>
          <div className="social-follow">
            <p>Follow us</p>
            <div>
              {data.allWp.nodes[0].locationData.LocationData.instagramHandle !==
                null && (
                <Link
                  to={
                    "https://www.instagram.com/" +
                    data.allWp.nodes[0].locationData.LocationData
                      .instagramHandle
                  }
                >
                  <InstagramIcon /> <span>Follow us</span>
                </Link>
              )}
              {data.allWp.nodes[0].locationData.LocationData.twitterHandle !==
                null && (
                <Link
                  to={
                    "https://www.twitter.com/" +
                    data.allWp.nodes[0].locationData.LocationData.twitterHandle
                  }
                >
                  <TwitterIcon /> <span>Follow us</span>
                </Link>
              )}
              {data.allWp.nodes[0].locationData.LocationData.facebookHandle !==
                null && (
                <Link
                  to={
                    "https://www.facebook.com/" +
                    data.allWp.nodes[0].locationData.LocationData.facebookHandle
                  }
                >
                  <FacebookIcon /> <span>Like us</span>
                </Link>
              )}
            </div>
          </div>
        </div>
        <div className="category-nav scrollable">
          <DragScroll className="scroll-inner" height={540} width={100}>
            {data.allWpCategory.nodes.map((cat, index) => {
              let link = "";
              if (cat.slug === "all") {
                link = cat.uri.replace("all/", "");
              } else {
                link = cat.uri;
              }
              return (
                <Link
                  to={link}
                  data-active={props.cat.name === cat.name ? true : false}
                >
                  {cat.name}
                </Link>
              );
            })}
          </DragScroll>
        </div>
        {visibleData.map((post, index) => {
          let image = null;
          if (post.featuredImage.node.localFile) {
            if (post.featuredImage.node.localFile.childImageSharp) {
              image = (
                <GatsbyImage
                  image={
                    post.featuredImage.node.localFile.childImageSharp
                      .gatsbyImageData
                  }
                  alt={post.featuredImage.node.altText}
                />
              );
            } else {
              // Gifs do not have a childImageSharp
              <div className="gatsby-image-wrapper">
                <div style={{ paddingTop: "78%" }} />
                <img
                  src={post.featuredImage.node.localFile.publicURL}
                  alt={post.featuredImage.node.altText}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    objectPosition: "center center",
                  }}
                />
              </div>;
            }
          } else {
            // This is a preview image
            image = (
              <div className="gatsby-image-wrapper">
                <div style={{ paddingTop: "78%" }} />
                <img
                  src={post.featuredImage.node.mediaItemUrl}
                  alt={post.featuredImage.node.altText}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    objectPosition: "center center",
                  }}
                />
              </div>
            );
          }
          let date = "";
          date = new Date(post.date);
          date = formatDate(date);
          return (
            <div className="list-item" key={post.id}>
              {image}
              <div className="con-wrap">
                <p className="date">{date}</p>
                <h2>
                  <Link to={`/${themeOptions.blogPath}/${post.slug}`}>
                    {post.title}
                  </Link>
                </h2>
                <p>
                  {ReactHtmlParser(
                    replaceMasks(post.excerpt.replace(/(<([^>]+)>)/gi, ""))
                  )}
                </p>

                <Link
                  to={`/${themeOptions.blogPath}/${post.slug}`}
                  style={{ textDecoration: "none" }}
                  className="cta"
                >
                  Discover More
                </Link>
              </div>
            </div>
          );
        })}

        {!allInView && (
          <button className={classes.Button} onClick={() => setPage(page + 1)}>
            {" "}
            Load More{" "}
          </button>
        )}
      </div>
    </div>
  );
};

BlogListing.propTypes = {
  attributes: PropTypes.object.isRequired,
};

import React, { Component } from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import { BlogListing } from "../components/BlogListing";
import { CgbCalloutCtaBlock } from "../components/blocks/CgbCalloutCtaBlock"; // eslint-disable-line

/**
 * This page should query the posts based on the context provided and pass the results to the post-list block
 * No post-list block? Show an error
 */
class ListPosts extends Component {
  render() {
    const meta = {};
    const { name, themeOptions } = this.props.data.allWp;
    const { pagePath } = this.props.pageContext;

    this.props.pageContext.posts = this.props.data.wpCategory.posts.nodes;

    // let subscribeObject = {
    //     attributes: {
    //         className: "mb-0",
    //         contained: false,
    //     },
    //     innerBlocks: [
    //         {
    //             attributes: {
    //                 align: null,
    //                 alt: "",
    //                 caption: "",
    //                 height: null,
    //                 href: "",
    //                 id: 452,
    //                 linkClass: "",
    //                 linkDescription: "none",
    //                 linkTarget: "",
    //                 rel: "",
    //                 sizeSlug: "large",
    //                 title: "",
    //                 url: "https://rob-cdn.ams3.digitaloceanspaces.com/website/general/signup_newsletter_lrg.png",
    //                 urlSharp: {
    //                     childImageSharp: {
    //                         fluid: {
    //                             aspectRatio: 1.4461538461538461,
    //                             base64: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsSAAALEgHS3X78AAAB8ElEQVQ4y22TyUokQRCGq7vapcXxJm4HQQdEZMCD4EFRDyrixRZc5iIiKDgMCHpyBqUFD64g4tKCreCCJ8Un0ZfIPJg3+w38ojqqLcsu+MioiMw/IyMjPa/MV3iz3vubAUZn+Q9sH5KlOc5WQP+7M5eMnepLfBESEZwqGthJ8GObdeBbhzPsl4IzL2yaVUE/EBBETIUS2D47e5Fs6lg8w3gBVzBLvBOhG8mU+fhMk04uHk0XJr9k42wvbLP4jvE/C39FYkcwE5zM2TVY/HZcPVIdm0wjco7vhIwz+NKxuaMI5D/FTRvi1+FOpcwQWcB28AojsdolgJqaBLEnaNUSpVTnFHpCwSp1bkAWxinDsewK/8hoQOqoc+RCVjXTFCTVPynl8WI1GySD3OfRbDO+KdhlYV4v5SHaEZzKD9oqsO25nL+GwF8Cz9iTjH/4byrTm5X4h1jcoIIVsWRa8D2K0QhziFVqYAz6NMOgvtJGZR+As9UwwWYnzJEW+h0GfiC4RHb32DmCh9oOaf5TZdqpm0w3GWkns4VgTzS4ByK0DDXqG0F0R2oXaZV6mNfGzmlta0txZ4ovBaMr0k+zIs5CueEVYuniRdkDuCW+RmY/Y9nyxoNW+lYPOVoG2sO+oobPsI/QcEHbI3xRRUypl8Nb/wD3ijVoLHCM7wAAAABJRU5ErkJggg==",
    //                             sizes: "(max-width: 94px) 100vw, 94px",
    //                             src: "/static/ae80a86c20c87ccfdc01392494f3a28c/f0788/signup_newsletter_lrg.png",
    //                             srcSet: "/static/ae80a86c20c87ccfdc01392494f3a28c/f0788/signup_newsletter_lrg.png 94w",
    //                             srcSetWebp: "/static/ae80a86c20c87ccfdc01392494f3a28c/404d4/signup_newsletter_lrg.webp 94w",
    //                             srcWebp: "/static/ae80a86c20c87ccfdc01392494f3a28c/404d4/signup_newsletter_lrg.webp",
    //                         },
    //                         id: "4048f074-0de9-5506-8d66-aca911f746e7",
    //                         publicURL: "/static/ae80a86c20c87ccfdc01392494f3a28c/signup_newsletter_lrg.png",
    //                     }
    //                 },
    //                 width: null,
    //                 __typename: "WpCoreImageBlockAttributes",
    //             },
    //             innerBlocks: [],
    //             name: "core/image",
    //             __typename: "WpCoreImageBlock",
    //         },
    //         {
    //             attributes: {
    //                 align: null,
    //                 backgroundColor: null,
    //                 className: null,
    //                 content: "Stay up to date with news and events",
    //                 direction: null,
    //                 textColor: null,
    //                 __typename: "WpCoreParagraphBlockAttributes",
    //             },
    //             innerBlocks: [],
    //             name: "core/paragraph",
    //             __typename: "WpCoreParagraphBlock",
    //         },
    //         {
    //             attributes: {
    //                 align: "right",
    //                 backgroundColor: "primary",
    //                 borderRadius: 4,
    //                 className: null,
    //                 gradient: null,
    //                 linkTarget: "",
    //                 placeholder: null,
    //                 rel: "",
    //                 text: "Sign up to our newsletter",
    //                 textColor: "foreground_primary",
    //                 title: "",
    //                 url: "/sign-up/",
    //                 __typename: "WpCoreButtonBlockAttributes",
    //             },
    //             innerBlocks: [],
    //             name: "core/button",
    //             __typename: "WpCoreButtonBlock",
    //         }
    //     ]
    // }

    return (
      <Layout
        meta={meta}
        path={pagePath}
        title={name}
        themeOptions={themeOptions}
      >
        <BlogListing
          cat={this.props.data.wpCategory}
          sticky={this.props.data.allWpPost.nodes[0]}
          pageContext={this.props.pageContext}
        />

        {/* <CgbCalloutCtaBlock attributes={subscribeObject.attributes} innerBlocks={subscribeObject.innerBlocks}/> */}
      </Layout>
    );
  }
}

export const query = graphql`
  query($id: String!, $termID: Int!) {
    wpCategory(id: { eq: $id }) {
      id
      slug
      name
      posts {
        nodes {
          id
          slug
          uri
          title
          excerpt
          date
          featuredImage {
            node {
              mediaItemUrl
              id
              altText
              title
              localFile {
                id
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    width: 920
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                    transformOptions: { fit: COVER, cropFocus: CENTER }
                  )
                }
              }
            }
          }
        }
      }
    }
    allWpPost(
      filter: {
        isSticky: { eq: true }
        categories: { nodes: { elemMatch: { databaseId: { eq: $termID } } } }
      }
      limit: 1
    ) {
      nodes {
        id
        slug
        uri
        title
        excerpt
        date
        featuredImage {
          node {
            mediaItemUrl
            id
            altText
            title
            localFile {
              id
              publicURL
              childImageSharp {
                gatsbyImageData(
                  width: 1200
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                  transformOptions: { fit: COVER, cropFocus: CENTER }
                )
              }
            }
          }
        }
      }
    }
    allWp {
      nodes {
        themeOptions {
          accommodationActive
          roomBookingCTAText
          roomBookingImage
          roomBookingImageText
          roomBookingLink
          tableBookingAccountId
          tableBookingActive
          tableBookingBrandId
          tableBookingCTAText
          tableBookingImage
          tableBookingImageText
          tableBookingRestaurantId
          ThemeOptions {
            siteLogo {
              mediaItemUrl
            }
          }
        }
      }
    }
  }
`;

export default ListPosts;

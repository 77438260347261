import brickTexture from "../../images/brick-texture.jpg";
import whiteCalIcon from "../../images/white-cal.png";
import arrowIcon from "../../images/link-arrow.png";
import greyCalIcon from "../../images/grey-cal.png";
import dragIcon from "../../images/drag-scroll.png";

export default (theme) => ({
  blogPagePosts: {
    position: "relative",
    paddingBottom: 50,
    [theme.breakpoints.up("md")]: {
      paddingBottom: 100,
    },
    marginBottom: 36,
    "& .blog-list-banner": {
      padding: "24px 0 44px",
      marginBottom: "-20px",
      position: "relative",
      [theme.breakpoints.up("md")]: {
        padding: "48px 0 70px",
        marginBottom: "-50px",
      },
      "&::before": {
        content: '""',
        display: "block",
        backgroundColor: theme.palette.secondary.main,
        backgroundImage: "url(" + brickTexture + ")",
        backgroundPosition: "center",
        backgroundSize: "cover",
        top: 0,
        position: "absolute",
        bottom: 0,
        left: "50%",
        width: "100vw",
        transform: "translateX(-50vw)",
      },
      "& h1": {
        position: "relative",
        zIndex: "1",
        color: theme.palette.secondary.contrastText,
        marginBottom: 0,
        fontSize: 32,
        lineHeight: 1.25,
        textTransform: "uppercase",
        letterSpacing: "0.048em",
        [theme.breakpoints.up("md")]: {
          fontSize: 56,
          letterSpacing: "0.056em",
        },
      },
    },
    "& .listing-title": {
      marginBottom: 60,
      [theme.breakpoints.up("md")]: {
        display: "flex",
        flexDirection: "row",
        marginTop: 32,
        alignItems: "flex-end",
      },
      "& h2": {
        marginTop: 0,
        marginBottom: 42,
        textTransform: "uppercase",
        fontSize: 24,
        letterSpacing: "0.056em",
        lineHeight: 1.25,
        maxWidth: "75%",
        fontFamily: theme.headerFont,
        [theme.breakpoints.up("md")]: {
          fontSize: 36,
          lineHeight: 1.75,
          letterSpacing: "0.06em",
          flex: "0 0 66.666%",
          marginBottom: 0,
        },
      },
      "& .social-follow": {
        [theme.breakpoints.up("md")]: {
          flex: "0 0 33.333%",
          textAlign: "right",
        },
        "& p": {
          fontSize: 16,
          fontWeight: "bold",
          fontFamily: theme.headerFont,
          letterSpacing: "0.04em",
          lineHeight: 1.5,
          textTransform: "uppercase",
          [theme.breakpoints.up("md")]: {
            display: "inline-block",
            verticalAlign: "middle",
          },
        },
        "& > div": {
          textAlign: "justify",
          textAlignEnd: "justify",
          [theme.breakpoints.up("md")]: {
            display: "inline-block",
            verticalAlign: "middle",
          },
          "& a": {
            color: theme.palette.primary.main,
            textAlign: "center",
            width: "33.333%",
            display: "inline-block",
            [theme.breakpoints.up("md")]: {
              display: "inline-block",
              width: 56,
            },
            "& svg": {
              width: 26,
              height: 26,
            },
            "& span": {
              display: "none",
            },
          },
        },
      },
    },
    "& .category-nav": {
      width: "calc(100% + 36px)",
      margin: "0 -18px 24px",
      overflow: "hidden",
      height: 52,
      position: "relative",
      padding: "0 18px 0 36px",
      "&::before": {
        content: '""',
        width: 20,
        height: 20,
        left: 8,
        top: "50%",
        transform: "translateY(-50%)",
        position: "absolute",
        backgroundImage: "url(" + dragIcon + ")",
        backgroundPosition: "center",
        backgroundSize: "contain",
        opacity: 0.25,
      },
      "&::after": {
        bottom: 2,
        left: 0,
        right: 0,
        content: '""',
        backgroundColor: theme.palette.secondary.main,
        height: 1,
        position: "absolute",
        [theme.breakpoints.up("md")]: {
          height: 2,
          bottom: 3,
        },
      },
      [theme.breakpoints.up("md")]: {
        height: 60,
        margin: "0 0 24px",
        width: "100%",
        padding: "0 32px",
      },
      "& .scroll-inner": {
        width: "100% !important",
        whiteSpace: "nowrap",
      },
      "& a": {
        padding: "0 36px",
        lineHeight: "49px",
        fontFamily: theme.headerFont,
        color: theme.palette.primary.main,
        textDecoration: "none",
        textTransform: "uppercase",
        letterSpacing: "0.04em",
        display: "inline-block",
        fontSize: 14,
        border: "none",
        backgroundColor: "transparent",
        transition: "background-color .3s ease-in-out",
        cursor: "pointer",
        [theme.breakpoints.up("md")]: {
          padding: "0 40px",
          lineHeight: "56px",
          fontSize: 18,
        },
        "&:hover, &:focus": {
          backgroundColor: "rgba(255,255,255,0.75)",
        },
        '&[data-active="true"]': {
          backgroundColor: theme.palette.common.white,
          borderBottom: "2px solid " + theme.palette.secondary.main,
          color: theme.palette.secondary.main,
          boxShadow: "0 3px 32px rgba(0,0,0,0.16)",
          [theme.breakpoints.up("md")]: {
            borderBottom: "3px solid " + theme.palette.secondary.main,
          },
        },
      },
    },
    "& .blog-list": {
      position: "relative",
      zIndex: "1",
      "& .featured-item": {
        "& a": {
          position: "relative",
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "block",
          boxShadow: "0 3px 24px rgba(0,0,0,0.43)",
          marginBottom: 30,
          textDecoration: "none",
          overflow: "hidden",
          "& .gatsby-image-wrapper": {
            width: "100%",
            "&::before": {
              position: "absolute",
              content: '""',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              display: "block",
              zIndex: 1,
              boxShadow: "inset 0 -30px 20px " + theme.palette.secondary.main,
              [theme.breakpoints.up("md")]: {
                display: "none",
              },
            },
            "& > div": {
              height: "200px !important",
              widht: "100% !important",
              [theme.breakpoints.up("md")]: {
                height: "415px !important",
              },
            },
          },
          "& .top-meta": {
            position: "absolute",
            top: 20,
            left: 24,
            [theme.breakpoints.up("md")]: {
              top: 35,
              left: 60,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "calc(100% - 120px)",
              alignItems: "center",
              "& .line": {
                backgroundColor: theme.palette.common.white,
                height: 1,
                display: "block",
                flex: "1 0 auto",
              },
            },
            "& .tag": {
              color: theme.palette.common.white,
              fontSize: 12,
              lineHeight: 1,
              display: "block",
              textTrasnform: "uppercase",
              letterSpacing: "0.04em",
              fontFamily: theme.headerFont,
              fontWeight: "bold",
              textTransform: "uppercase",
              [theme.breakpoints.up("md")]: {
                fontSize: 18,
                marginRight: 24,
              },
            },
            "& .date": {
              display: "none",
              [theme.breakpoints.up("md")]: {
                display: "block",
              },
            },
          },
          "& .cta-wrap": {
            padding: 24,
            backgroundColor: theme.palette.secondary.main,
            [theme.breakpoints.up("md")]: {
              position: "absolute",
              bottom: 50,
              left: 60,
              backgroundColor: "transparent",
              maxWidth: 420,
              padding: 0,
            },
          },
          "& .date": {
            display: "block",
            color: theme.palette.secondary.contrastText,
            paddingBottom: 5,
            borderBottom: "1px solid " + theme.palette.secondary.contrastText,
            fontSize: 12,
            fontFamily: theme.headerFont,
            letterSpacing: "0.04em",
            lineHeight: 1.25,
            fontWeight: "bold",
            textTransform: "uppercase",
            marginBottom: 30,
            [theme.breakpoints.up("md")]: {
              display: "none",
              marginLeft: 24,
              fontSize: 14,
              borderBottom: "none",
              marginBottom: 0,
              paddingBottom: 0,
            },
            "&::before": {
              content: '""',
              width: 15,
              height: 15,
              display: "inline-block",
              marginRight: 10,
              backgroundImage: "url(" + whiteCalIcon + ")",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              verticalAlign: "bottom",
            },
          },
          "& .post-title": {
            fontFamily: theme.headerFont,
            fontSize: 18,
            lineHeight: 1.5,
            letterSpacing: "0.04em",
            marginBottom: 48,
            color: "white",
            display: "block",
            textTransform: "uppercase",
            [theme.breakpoints.up("md")]: {
              fontSize: 32,
              lineHeight: 1.25,
              marginBottom: 90,
            },
          },
          "& .cta": {
            fontFamily: theme.headerFont,
            fontSize: 16,
            lineHeight: 1.4,
            textTransform: "uppercase",
            color: theme.palette.primary.main,
            display: "inline-block",
            letterSpacing: "0.06em",
            "&::after": {
              width: 36,
              height: 13,
              display: "inline-block",
              marginLeft: 15,
              backgroundImage: "url(" + arrowIcon + ")",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              content: '""',
              transition: "margin-left .3s ease-in-out",
            },
            [theme.breakpoints.up("md")]: {
              fontSize: 18,
            },
          },
        },
      },
      "& .list-item:nth-of-type(1)": {
        display: "none",
      },
      "& .list-item": {
        backgroundColor: theme.palette.common.white,
        boxShadow: "0 3px 16px rgba(0,0,0,0.16)",
        marginBottom: 30,
        padding: 16,
        [theme.breakpoints.up("md")]: {
          padding: 40,
        },
        "& .gatsby-image-wrapper": {
          overflow: "hidden",
          "& > div": {
            paddingTop: "78% !important",
            "& img": {
              display: "none !important",
            },
          },
          [theme.breakpoints.up("md")]: {
            width: 275,
            float: "left",
            marginRight: 60,
          },
        },
        "& .con-wrap": {
          padding: "0 2px",
          overflow: "hidden",
          position: "relative",
          [theme.breakpoints.up("md")]: {
            width: "calc(100% - 335px)",
            float: "left",
            padding: 0,
          },
          "& h2": {
            color: theme.palette.secondary.main,
            fontFamily: theme.headerFont,
            fontSize: 16,
            lineHeight: 1.5,
            marginBottom: 24,
            marginTop: 0,
            letterSpacing: "0.04em",
            fontWeight: "600",
            textTransform: "uppercase",
            [theme.breakpoints.up("md")]: {
              fontSize: 24,
              lineHeight: 1.25,
            },
            "& a": {
              color: theme.palette.primary.main,
              textDecoration: "none !important",
              transition: "color .3s ease-in-out",
              "&:hover, &:focus": {
                color: theme.palette.primary.dark,
              },
            },
          },
          "& p.date": {
            display: "block",
            color: "#7f7f7f;",
            fontSize: 12,
            fontFamily: theme.headerFont,
            letterSpacing: "0.04em",
            lineHeight: 1.2,
            fontWeight: "bold",
            textTransform: "uppercase",
            marginBottom: 20,
            [theme.breakpoints.up("md")]: {
              fontSize: 14,
            },
            "&::before": {
              content: '""',
              width: 15,
              height: 15,
              display: "inline-block",
              marginRight: 10,
              backgroundImage: "url(" + greyCalIcon + ")",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              verticalAlign: "bottom",
            },
          },
          "& p": {
            fontSize: 14,
            marginBottom: 32,
            lineHeight: 2,
            [theme.breakpoints.up("md")]: {
              letterSpacing: "0.032em",
              marginBottom: 0,
              lineHeight: 1.5,
            },
          },
          "& .cta": {
            fontFamily: theme.headerFont,
            color: theme.palette.primary.main,
            fontSize: 0,
            lineHeight: 1.3,
            letterSpacing: "0.03em",
            textTransform: "uppercase",
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            background:
              "linear-gradient(to top, " +
              theme.palette.common.white +
              " 25%, rgba(255,255,255,0) 100%)",
            zIndex: 1,
            [theme.breakpoints.up("md")]: {
              display: "none",
            },
            "&::after": {
              width: 36,
              height: 13,
              display: "block",
              margin: "35px auto 10px",
              backgroundImage: "url(" + arrowIcon + ")",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              content: '""',
              transition: "margin-left .3s ease-in-out",
            },
          },
        },
        "&::after": {
          clear: "both",
          display: "table",
          content: '""',
        },
      },
    },
    "& ul.MuiPagination-ul": {
      justifyContent: "center",
    },
  },
  Button: {
    display: "block",
    alignItems: "center",
    justifyContent: "space-between",
    width: "fit-content",
    padding: 14,
    paddingInline: 28,
    border: "none",
    borderRadius: 4,
    textTransform: "uppercase",
    fontSize: 16,
    fontWeight: "bold",
    boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.1)",
    backgroundColor: theme.palette.primary.main,
    color: "white",
    fontFamily: theme.headerFont,
    letterSpacing: "0.06em",
    lineHeight: 1.15,
    textAlign: "left",
    marginBottom: 24,
    marginTop: 40,
    marginLeft: "auto",
    marginRight: "auto",
    transition: "background-color .3s ease-in-out",
    "&:hover, &:focus": {
      backgroundColor: "#72ce9b",
    },
    "& img": {
      marginTop: 2,
      width: 14,
      float: "right",
      marginLeft: 20,
      verticalAlign: "middle",
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: 0,
    },
  },
  blogPagePostsTheme: {
    ...theme.blogPagePostsTheme,
  },
});
